import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-56121940"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "loading_screen"
};
const _hoisted_2 = {
  class: "mask-pet"
};
const _hoisted_3 = ["src"];
import { ref, onMounted, onUnmounted } from "vue";
export default {
  __name: 'index',
  setup(__props) {
    const loadingImages = ref([{
      src: require("@/assets/img/BoardDetail/loading1.png")
    }, {
      src: require("@/assets/img/BoardDetail/loading2.png")
    }, {
      src: require("@/assets/img/BoardDetail/loading3.png")
    }, {
      src: require("@/assets/img/BoardDetail/loading4.png")
    }]);
    let currentIndex = ref(0);
    let intervalId = null;
    onMounted(() => {
      startLoadingAnimation();
    });
    onUnmounted(() => {
      clearInterval(intervalId);
    });
    function startLoadingAnimation() {
      intervalId = setInterval(() => {
        currentIndex.value = (currentIndex.value + 1) % loadingImages.value.length;
      }, 500); // 切换图片的时间间隔，单位毫秒，这里设置为1000毫秒（1秒）
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(loadingImages.value, (img, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "loading",
          key: index,
          style: _normalizeStyle({
            opacity: _unref(currentIndex) === index ? 1 : 0
          })
        }, [_createElementVNode("img", {
          src: img.src,
          alt: ""
        }, null, 8, _hoisted_3)], 4);
      }), 128))])]);
    };
  }
};
import { ElButton, ElNotification, ElPagination } from "element-plus";
export default {
  components: {
    ElButton,
    ElPagination
  },
  methods: {
    // 可以写入全局方法等
    GlobalElNotification({
      customClass,
      title,
      message,
      icon = "",
      type = "",
      duration = 3000,
      showClose = true,
      dangerouslyUseHTMLString = false
    }) {
      ElNotification({
        customClass,
        title,
        message,
        icon,
        type,
        duration,
        showClose,
        dangerouslyUseHTMLString
      });
    }
  }
};
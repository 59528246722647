import { Connection } from "@solana/web3.js";
import store from "@/store";
import { myVanToast } from "../plugins/vant";
// start: 1.0 仅链接phantom 2.0钱包适配器
export const getProvider = () => {
    if ("phantom" in window) {
        const provider = window.phantom?.solana;

        if (provider?.isPhantom) {
            return provider;
        }
    } else {
        store.commit("updateAccountsChanged", "");
        store.commit("updateIsConnectWallet", false);
        myVanToast({ message: "No wallet plugin detected", duration: 3000, position: "top" });
        return false;
    }
};

export const checkWalletConnection = () => {
    if ("phantom" in window) {
        const provider = window.phantom?.solana;

        if (provider?.isPhantom && provider?.isConnected) {
            return true;
        } else {
            myVanToast({ message: "Please connect your wallet", duration: 3000, position: "top" });
            store.commit("updateAccountsChanged", "");
            store.commit("updateIsConnectWallet", false);
            return false;
        }
    } else {
        store.commit("updateAccountsChanged", "");
        store.commit("updateIsConnectWallet", false);
        myVanToast({ message: "No wallet plugin detected", duration: 3000, position: "top" });
        return false;
    }
};
// end

export const createConnection = () => {
    try {
        return new Connection(process.env.VUE_APP_SOLSCAN_RPC, "confirmed");
    } catch (error) {
        return null;
    }
};

import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { getCoinNotify } from "@/service/api";
import fetchDataFromIPFS from "@/utils/ipfs";
const debug = process.env.NODE_ENV !== "production";

const state = {
    lang: "en-US",
    globalLoading: false,
    publicKeyAddress: "",
    address: "",
    isConnectWallet: false,
    newGameInfo: {},
    activeIndex: 0, //用心信息切换tab标识
    userInfoObj: {}, //用户信息
    boardDetailObj: {}, //币详情
    latestTradeObj: {}, //最后一条交易记录
    mintDetailObj: {}, //解析ipfs得到的币详情信息
    loginSign: "",
    lastSignedAddress: "",
    // otherAddress: "", //非登录用户的钱包地址
    latestNotifyInfo: {},
    lotteryTicketsToastShow: false, //奖券通知
    otherAddress: "", //非登录用户的钱包地址
    maxRuningValue: "5", //买卖滑点最大值
    priorityFee: "0.05",
    marketUp: "0", //创建代币的市值
    advPayer: {},
    pollingTimeoutId: null,
    boardBgType: 0,
    isShowRadium: false,
};

const mutations = {
    updateShowRadium(state, value) {
        state.isShowRadium = value;
    },
    updateLang(state, value) {
        state.lang = value;
    },
    updateGlobalLoading(state, value) {
        state.globalLoading = value;
    },
    updatePublicKeyAddress(state, value) {
        state.publicKeyAddress = value;
    },
    updateAccountsChanged(state, value) {
        state.address = value;
    },
    updateIsConnectWallet(state, value) {
        state.isConnectWallet = value;
    },
    updateNewGameInfo(state, value) {
        state.newGameInfo = value;
    },
    updateActiveIndex(state, value) {
        state.activeIndex = value;
    },
    updateUserInfo(state, value) {
        state.userInfoObj = value;
    },
    updateBoardDetail(state, value) {
        state.boardDetailObj = value;
    },
    updateLoginSign(state, value) {
        state.loginSign = value;
    },
    updateLastSignedAddress(state, value) {
        state.lastSignedAddress = value;
    },
    // 头部通知
    updateLatestNotifyInfo(state, value) {
        state.latestNotifyInfo = value;
    },
    // 奖券通知
    updateLotteryTicketsToastShow(state, value) {
        state.lotteryTicketsToastShow = value;
    },
    updateMintDetail(state, value) {
        state.mintDetailObj = value;
    },
    // updateOtherAddress(state, value) {
    //     state.otherAddress = value;
    // },
    updateMaxRuningValue(state, value) {
        state.maxRuningValue = value;
    },
    updatePriorityFee(state, value) {
        state.priorityFee = value;
    },
    updateMarketUp(state, value) {
        state.marketUp = value;
    },
    updateLatestTradeObj(state, value) {
        state.latestTradeObj = value;
    },
    updateAdvPayer(state, value) {
        state.advPayer = value;
    },
    setPollingTimeoutId(state, timeoutId) {
        state.pollingTimeoutId = timeoutId;
    },
    updateBoardBgType(state, value) {
        state.boardBgType = value;
    },
};
const actions = {
    async store_getCoinNotifyFun({ state, commit }) {
        let hill_ipfs_info = {};
        let hill2_ipfs_info = {};
        async function getCoinNotifyFun() {
            try {
                const res = await getCoinNotify({});
                if (!res.error_code) {
                    const data = res.data;
                    // 请求 ipfs
                    if (data.latest_promote?.ipfs_url && hill_ipfs_info?.ipfs_url !== data.latest_promote?.ipfs_url) {
                        try {
                            hill_ipfs_info = await fetchDataFromIPFS(data.latest_promote.ipfs_url);
                            hill_ipfs_info.ipfs_url = data.latest_promote.ipfs_url;
                        } catch (error) {
                            hill_ipfs_info = {};
                        }
                    }

                    // 请求 ipfs
                    if (data.latest_hill?.uri && hill2_ipfs_info?.ipfs_url !== data.latest_hill?.uri) {
                        try {
                            hill2_ipfs_info = await fetchDataFromIPFS(data.latest_hill.uri);
                            hill2_ipfs_info.ipfs_url = data.latest_hill.uri;
                        } catch (error) {
                            hill2_ipfs_info = {};
                        }
                    }
                    commit("updateLatestNotifyInfo", {
                        calculate_first: data?.calculate_first,
                        calculate_second: data?.calculate_second,
                        latest_promote: data?.latest_promote,
                        hill_ipfs_info,
                        latest_hill: data?.latest_hill,
                        hill2_ipfs_info,
                        notifyList: data?.latest_trades,
                        sol_price: data?.sol_price,
                    });
                }
            } catch (error) {
                console.error("Failed to fetch coin notify:", error);
            } finally {
                // 清理旧的定时器
                if (state.pollingTimeoutId) {
                    clearTimeout(state.pollingTimeoutId);
                    commit("setPollingTimeoutId", null);
                }
                // 设置新的定时器
                const timeoutId = setTimeout(getCoinNotifyFun, 5000);
                commit("setPollingTimeoutId", timeoutId);
            }
        }
        // 启动轮询
        getCoinNotifyFun();
    },
};

export default createStore({
    state,
    mutations,
    actions,
    strict: debug,
    plugins: [createPersistedState()],
});

import { PublicKey } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { createConnection } from "./connect";

// 会调用rpc慎用
export const isSplTokenAccount = async (address) => {
    try {
        const connection = createConnection();
        const key = new PublicKey(address);
        const accountInfo = await connection.getAccountInfo(key);

        if (!accountInfo) return false; // 账户不存在

        // 检查数据长度
        // if (accountInfo.data.length !== 165) return false;

        // 检查所有者
        if (!accountInfo.owner.equals(TOKEN_PROGRAM_ID)) return false;

        return true;
    } catch (error) {
        return false;
    }
};

// 验证地址是否符合solana地址
export const isValidSolanaAddress = (address) => {
    try {
        new PublicKey(address);
        return true;
    } catch (error) {
        return false;
    }
};

// 根据配置使用不同网络
export const getSolanaNetwork = () => {
    const network = process.env.VUE_APP_SOLSCAN_WALLET_CLUSTER;
    switch (network) {
        case "mainnet":
            return WalletAdapterNetwork.Mainnet;
        case "testnet":
            return WalletAdapterNetwork.Testnet;
        case "devnet":
        default:
            return WalletAdapterNetwork.Devnet;
    }
};

const Home = () => import("@/views/Home");
const Board = () => import("@/views/Board");
const Publish = () => import("@/views/Publish");
const Burn = () => import("@/views/Burn");
const BurnHistory = () => import("@/components/Burn/history");
// 创建代币
const Create = () => import("@/views/Create");

const SimpBoardDetail = () => import("@/views/SimpBoardDetail");
const BoardDetail = () => import("@/views/BoardDetail/indexAll");
const Charts = () => import("@/views/Charts");
const Trade = () => import("@/views/Trade/indexAll");
const Txs = () => import("@/views/Txs/indexAll");
const UserInfo = () => import("@/views/UserInfo");
const EditProfile = () => import("@/views/EditProfile");
const TokensHold = () => import("@/views/TokensAndFollows/tokens-hold");
const TokenCreated = () => import("@/views/TokensAndFollows/tokens-created");
const Tokenlisted = () => import("@/views/TokensAndFollows/tokens-listed");
// const Followers = () => import("@/views/TokensAndFollows/followers");
// const Following = () => import("@/views/TokensAndFollows/following");
const BazingaZone = () => import("@/views/BazingaZone");
const Coupon = () => import("@/views/Coupon");
const NotFound = () => import("@/views/NotFound");
const MemeZone = () => import("@/views/MemeZone");
const ProjectHistory = () => import("@/views/MemeZoneHistory");
const RewardHistory = () => import("@/views/RewardHistory");
const PartnerShip = () => import("@/views/PartnerShip");
const MemeOneHundred = () => import("@/views/MemeOneHundred");
const TradingContest = () => import("@/views/TradingContest");
const ContestList = () => import("@/views/TradingContest/list");
const ContestDetails = () => import("@/views/TradingContest/details");
const ContestRanking = () => import("@/views/TradingContest/ranking");
const CreateCoinHistory = () => import("@/views/CreateCoinRewardHistory");
const ReferFriends = () => import("@/views/ReferFriends");
const TradingBots = () => import("@/views/TradingBotsNew");
const DevRewards = () => import("@/views/DevRewards");
const ReferRewards = () => import("@/views/ReferRewards");
const TradingMatch = () => import("@/views/TradingMatch");
const ContestListV2 = () => import("@/views/TradingMatch/list");
const ContestDetailsV2 = () => import("@/views/TradingMatch/details");
const ContestHistoryV2 = () => import("@/views/TradingMatch/history");
const routes = [
    {
        path: "/home",
        name: "home",
        component: Home,
    },
    {
        path: "/",
        redirect: {
            name: "memeboard",
        },
    },
    {
        path: "/board",
        redirect: {
            name: "memeboard",
        },
    },
    {
        path: "/memeboard",
        name: "memeboard",
        component: Board,
        children: [
            {
                path: "refer/:id",
                component: Board,
            },
        ],
    },
    {
        path: "/publish",
        name: "publish",
        component: Publish,
    },
    {
        path: "/burn",
        name: "burn",
        component: Burn,
    },
    {
        path: "/burn-history",
        name: "burn-history",
        component: BurnHistory,
        meta: {
            requiresAuth: true,
            redirectName: "burn",
        },
    },
    {
        path: "/create",
        name: "create",
        component: Create,
    },

    {
        path: "/list/:id",
        name: "simpBoardDetail",
        component: SimpBoardDetail,
    },
    {
        path: "/meme/:id",
        name: "boardDetail",
        component: BoardDetail,
    },
    {
        path: "/trade/:id",
        name: "trade",
        component: Trade,
    },
    {
        path: "/charts/:id",
        name: "charts",
        component: Charts,
    },
    {
        path: "/txs/:id",
        name: "txs",
        component: Txs,
    },
    {
        path: "/player",
        name: "userInfo",
        component: UserInfo,
        children: [
            {
                name: "tokensHoldWithId",
                path: "tokenhold/:id",
                component: TokensHold,
            },
            {
                path: "tokencreated/:id",
                name: "tokenCreatedWithId",
                component: TokenCreated,
            },
            {
                path: "tokenlisted/:id",
                name: "tokenListedWithId",
                component: Tokenlisted,
            },
            // {
            //     path: "followers",
            //     name: "followers",
            //     component: Followers,
            // },
            // {
            //     path: "following",
            //     name: "following",
            //     component: Following,
            // },
        ],
    },
    {
        path: "/editProfile",
        name: "editProfile",
        component: EditProfile,
    },
    {
        path: "/memezone",
        name: "memezone",
        component: MemeZone,
    },
    {
        path: "/project-history",
        name: "project-history",
        component: ProjectHistory,
    },
    {
        path: "/bazinga-zone",
        redirect: {
            name: "bazingazone",
        },
    },
    {
        path: "/bazingazone",
        name: "bazingazone",
        component: BazingaZone,
    },
    {
        path: "/coupon",
        name: "coupon",
        component: Coupon,
    },
    {
        path: "/rewardHistory",
        name: "rewardHistory",
        component: RewardHistory,
    },
    {
        path: "/partnership/tradingview",
        name: "partnerShip",
        component: PartnerShip,
    },
    {
        path: "/meme100",
        name: "meme100",
        component: MemeOneHundred,
    },
    {
        path: "/trading",
        name: "trading",
        component: TradingContest,
        redirect: { name: "contest-list" },
        children: [
            {
                path: "contest",
                name: "contest-list",
                component: ContestList,
            },
            {
                path: "details",
                name: "contest-details",
                component: ContestDetails,
            },
            {
                path: "ranking",
                name: "contest-ranking",
                component: ContestRanking,
            },
        ],
    },
    {
        path: "/contest",
        name: "contest",
        component: TradingMatch,
        redirect: { name: "contest-listV2" },
        children: [
            {
                path: "v2",
                name: "contest-listV2",
                component: ContestListV2,
            },
            {
                path: "fomo3d",
                name: "contest-detailsV2",
                component: ContestDetailsV2,
            },
            {
                path: "history",
                name: "tradingHistory",
                component: ContestHistoryV2,
            },
        ],
    },
    {
        path: "/coinhistory",
        name: "coinhistory",
        component: CreateCoinHistory,
    },
    {
        path: "/referFriends",
        name: "referFriends",
        component: ReferFriends,
    },
    {
        path: "/tradingBots",
        name: "tradingBots",
        component: TradingBots,
    },
    {
        path: "/devRewards",
        name: "devRewards",
        component: DevRewards,
    },
    {
        path: "/referRewards",
        name: "referRewards",
        component: ReferRewards,
    },

    {
        path: "/404",
        name: "404",
        component: NotFound,
    },
    {
        // 会匹配所有路径
        path: "/:pathMatch(.*)*",
        component: NotFound,
    },
];
export default routes;

export default {
    public: {
        copy_success: "复制成功",
        copy_success_1: "网址已复制，粘贴到浏览器即可访问！",
        download_success: "保存成功",
        button: {
            text: "确定",
            jump: "跳过",
            text_ok: "确认",
            text_ok1: "好的",
            cancel: "取消",
        },
        networkError: {
            text1: "Error, please try again!",
            text2: "Login expired or error occurred, please login again!",
            text3: "The requested resource does not exist!",
            mail: "Please open it in the Supe App!",
            errorProcess: "You are not in the App environment, please open the Supe App to visit!",
            errorMethodsNotDefine: "The method does not exist!",
        },
    },
    refresh: {
        text1: "向下拖动刷新...",
        text2: "松开刷新...",
        text3: "加载中...",
        text4: "加载成功~",
        text5: "没有更多了~",
        noMore: "没有更多数据~",
    },
    captchaFunError: {
        text1: "验证加载失败，请尝试刷新页面或切换网络！",
        text2: "发送过于频繁，请稍后再试！",
        text3: "发送过于频繁，请勿恶意调用！",
    },
    header3: {
        title_publish: "LIST ON MEMEBOARD",
        title_create: "CREATE MEME",
        title_burn_history: "$HAPPY REWARD",
        title_bazinga_zone: "BAZINGA ZONE",
        title_meme_zone: "MEME ZONE",
        title_meme_zone_list: "PROJECT HISTORY",
    },
    home: {},
};

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
import { computed, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import IPConfirmation from "@/components/IPConfirmation";
import LotteryTicketsToast from "@/components/LotteryTicketsToast";
export default {
  __name: 'App',
  setup(__props) {
    const store = useStore();
    const lang = computed(() => store.state.lang);
    const pollingTimeoutId = computed(() => store.state.pollingTimeoutId);
    const globalLoading = computed(() => store.state.globalLoading);
    store.dispatch("store_getCoinNotifyFun");
    onBeforeUnmount(() => {
      // 清理旧的定时器
      if (pollingTimeoutId.value) {
        clearTimeout(pollingTimeoutId.value);
        store.commit("setPollingTimeoutId", null);
      }
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_Loading = _resolveComponent("Loading");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(['app', 'app-' + lang.value])
      }, [_createVNode(_component_router_view), globalLoading.value ? (_openBlock(), _createBlock(_component_Loading, {
        key: 0
      })) : _createCommentVNode("", true), _createVNode(_unref(IPConfirmation)), _createVNode(_unref(LotteryTicketsToast))], 2);
    };
  }
};